<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="#service">
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content" style="user-select: none;">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "heart",
            title: "AI Strategy",
            desc: `A business strategy and roadmap for pursuing innovative projects with AI and achieving strategic alignments between business and technology.`,
          },
          {
            icon: "database",
            title: "AI Data",
            desc: `A secure data transformation making high-quality data available to AI and empowering people with access to trustworthy data.`,
          },
          {
            icon: "upload-cloud",
            title: "AI Product",
            desc: `A cutting-edge digital product for modernizing business operations and leading the market with R&D of AI.`,
          },
          // {
          //   icon: "monitor",
          //   title: "Mobile Development",
          //   desc: `I throw myself down among the tall grass by the stream as I lie
          //       close to the earth.`,
          // },
          // {
          //   icon: "users",
          //   title: "Marketing & Reporting",
          //   desc: `I throw myself down among the tall grass by the stream as I lie
          //       close to the earth.`,
          // },
          // {
          //   icon: "monitor",
          //   title: "Mobile Development",
          //   desc: ` I throw myself down among the tall grass by the stream as I lie
          //       close to the earth.`,
          // },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

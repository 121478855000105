<template>
  <v-row>
    <!-- Start Single Team Area  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="6"
      v-for="team in teamContent"
      :key="team.id"
    >
      <div class="team">
        <div class="thumbnail">
          <img :src="team.src" alt="Team Images" />
        </div>
        <div class="content">
          <h4 class="title">{{ team.title }}</h4>
          <p class="designation">{{ team.designation }}</p>
          <p class="designation"><span style="color:#60a5a0;">{{ team.function }}</span></p>
        </div>
        <ul class="social-icon">
          <li v-for="(social, i) in team.socialList" :key="i">
            <a :href="social.url" target="_blank"
            ><i class="fab" :class="social.icon"></i
            ></a>
          </li>
        </ul>
      </div>
    </v-col>
    <!-- End Single Team Area  -->
  </v-row>
</template>

<script>
  import SocialIcon from "../../components/social/Social";
  export default {
    components: {
      SocialIcon,
    },
    data() {
      return {
        teamContent: [
          {
            id: 1,
            src: require("../../assets/images/team/team-01.jpg"),
            title: "Tomas Turek",
            designation: "Founder, Managing Director",
            function: "AI PRODUCT",
            socialList: [
              {
                icon: "fa-linkedin-in",
                url: "https://www.linkedin.com/in/tomas-turek-54348aba",
              },
            ],
          },
          {
            id: 2,
            src: require("../../assets/images/team/team-02.jpg"),
            title: "Dr. Lamiae Azizi",
            designation: "Scientific Advisor",
            function: "AI INNOVATION",
            socialList: [
              {
                icon: "fas fa-globe",
                url: "https://scholar.google.fr/citations?user=fThAv-cAAAAJ&hl=en",
              },
            ],
          },
          {
            id: 3,
            src: require("../../assets/images/team/team-03.jpg"),
            title: "Tom Clark",
            designation: "Commercial Director, New Ventures",
            function: "AI MARKET",
            socialList: [
              {
                icon: "fa-linkedin-in",
                url: "https://www.linkedin.com/in/tomclark76",
              },
            ],
          },
          {
            id: 4,
            src: require("../../assets/images/team/team-04.jpg"),
            title: "Lata McNulty",
            designation: "Financial Advisor",
            function: "AI RISK",
            socialList: [
              {
                icon: "fa-linkedin-in",
                url: "https://www.linkedin.com/in/lata-mcnulty-a2888b7",
              },
            ]
          },
          // {
          //   id: 5,
          //   src: require("../../assets/images/team/team-02.jpg"),
          //   title: "Jone Due",
          //   designation: "Sr. Graphic Designer",
          // },
          // {
          //   id: 6,
          //   src: require("../../assets/images/team/team-03.jpg"),
          //   title: "Jone Due",
          //   designation: "Sr. Web Developer",
          // },
        ],
      };
    },
  };
</script>

<template>
  <v-row>
    <!-- Start Single Counterup  -->
    <v-col
      lg="4"
      md="4"
      sm="4"
      cols="12"
      v-for="(counter, i) in counterUpContent"
      :key="i"
    >
      <div class="rn-counterup counterup_style--1">
        <h5 class="counter count">
          <VisibilitySensor @change="onChange">
            <countTo
                :endVal="status ? counter.endVal : 0"
                :autoplay="countUp"
                :duration="3000"
                :decimals="counter.ds"
                :prefix="counter.px"
            ></countTo>
          </VisibilitySensor>
          <sup style="vertical-align: baseline; position: relative; left: -0.6em; top: -1.1em; font-size: 0.4em;">{{ counter.sx }}</sup>
        </h5>
        <p class="description">
          {{ counter.desc }}
        </p>
      </div>
    </v-col>
    <!-- Start Single Counterup  -->
  </v-row>
</template>

<script>
  import VisibilitySensor from "vue-visibility-sensor";
  import countTo from "vue-count-to";

  export default {
    components: {
      countTo,
      VisibilitySensor,
    },
    data() {
      return {
        countUp: true,
        status: false,
        counterUpContent: [
          {
            endVal: 20,
            ds: 0,
            px: "",
            sx: "%",
            desc: `revenue is generated from AI in organisations adopting AI`,
          },
          {
            endVal: 75,
            ds: 0,
            px: "",
            sx: "%",
            desc: `executives are struggling to adopt AI in their organisations`,
          },
          {
            endVal: 3.1,
            ds: 1,
            px: "$",
            sx: "trillion",
            desc: `global GDP is generated from AI`,
          },
        ],
      };
    },
    methods: {
      onChange(isVisible) {
        if (isVisible) {
          this.status = true;
        }
      },
    },
  };
</script>
